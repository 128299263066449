<template>
  <simple-layout>
    <router-view></router-view>
  </simple-layout>
</template>

<script>
import SimpleLayout from "@/components/layouts/SimpleLayout";
export default {
  name: "index",
  components: {SimpleLayout},
}
</script>

<style scoped>

</style>